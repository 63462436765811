import React, { FunctionComponent, useCallback } from 'react';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';
import { RecursivePartial, IOptions, Engine } from 'tsparticles-engine';

export const BannerParticles: FunctionComponent = (): JSX.Element => {
  const particlesOptions: RecursivePartial<IOptions> = {
    fpsLimit: 60,
    fullScreen: false,
    particles: {
      number: {
        value: 200,
        density: {
          enable: false,
        },
      },
      opacity: {
        value: 0.7,
        random: true,
      },
      size: {
        value: 2.5,
        random: true,
        anim: {
          speed: 4,
          size_min: 0.3,
        },
      },
      lineLinked: {
        enable: false,
      },
      move: {
        enable: true,
        speed: 0.5,
        direction: 'top',
        random: true,
        out_mode: 'out',
      },
    },
    interactivity: {
      detect_on: 'window',
      events: {
        onHover: {
          enable: true,
          mode: 'repulse',
        },
        onClick: {
          enable: true,
          mode: 'repulse',
        },
      },
      modes: {
        bubble: {
          distance: 250,
          duration: 2,
          size: 0,
          opacity: 0,
        },
        repulse: {
          distance: 100,
          duration: 10,
        },
      },
    },
  };

  const particlesInit = useCallback(async (engine: Engine) => {
    await loadFull(engine);
  }, []);

  return <Particles className={'banner-particles'} options={particlesOptions} init={particlesInit} />;
};
