import React, { FunctionComponent } from 'react';
import { PROFILE_URL } from '../../constants';
import { UserOutlined } from '@ant-design/icons';
import { Row, Image, Col } from 'antd';

export const AboutSection: FunctionComponent = (): JSX.Element => {
  return (
    <div className={'section'}>
      <h2>
        <UserOutlined /> About Me
      </h2>
      <Row gutter={[16, 16]}>
        <Col className={'gutter-row'} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }}>
          <Image src={PROFILE_URL} />
        </Col>
        <Col className={'gutter-row'} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 16 }}>
          <p>
            Certified HVAC Technician. I&apos;m passionate about my trade and always provide quality service. I&apos;m a
            believer in honest and transparent pricing. You can contact me anytime of day or night by phone or email. I
            look forward to helping you out!
          </p>
        </Col>
      </Row>
    </div>
  );
};
