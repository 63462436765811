import React, { FunctionComponent } from 'react';
import { createBrowserHistory } from 'history';
import { AppRoutes } from './AppRoutes';
import { Layout } from 'antd';
import { Footer } from './components/layout/Footer';
import { Navigation } from './components/layout/Navigation';
import { BrowserRouter } from 'react-router-dom';

export const history = createBrowserHistory();

export const App: FunctionComponent = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Layout className={'base-layout'}>
        <Navigation />
        <AppRoutes />
        <Footer />
      </Layout>
    </BrowserRouter>
  );
};
