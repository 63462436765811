import React, { FunctionComponent, useEffect, useState } from 'react';
import { Layout, Row } from 'antd';
import { RouteMap } from '../../models/enums/RouteMap';
import { useLocation } from 'react-router-dom';
import { PHONE_NUMBER } from '../../constants';
import { PhoneFilled } from '@ant-design/icons';

export const Navigation: FunctionComponent = (): JSX.Element => {
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleScroll = () => {
    setIsScrolled(window.scrollY > 100);
  };

  const getNavigationClassName = (): string => {
    const baseClass = 'navigation';

    if (location.pathname === RouteMap.HOME) {
      return `${baseClass} fixed${isScrolled ? ' scrolled' : ''}`;
    }

    return `${baseClass} scrolled`;
  };

  return (
    <Layout.Header className={getNavigationClassName()}>
      <Row justify={'start'}>
        <div className={'call-now'}>
          <a href={`tel:${PHONE_NUMBER}`}>
            <PhoneFilled /> {PHONE_NUMBER}
          </a>
        </div>
      </Row>
    </Layout.Header>
  );
};
