import React, { FunctionComponent } from 'react';
import { BANNER_IMAGE_URL, BANNER_VIDEO_URL } from '../../constants';
import { BannerParticles } from '../banner-particles/BannerParticles';

export const BannerSection: FunctionComponent = (): JSX.Element => {
  return (
    <div className={'banner'}>
      <video id={'bgvid'} poster={BANNER_IMAGE_URL} playsInline autoPlay muted loop>
        <source src={BANNER_VIDEO_URL} type={'video/mp4'} />
      </video>
      <BannerParticles />
      <div className={'banner-content'}>
        <div className={'banner-title'}>
          <h1>Long Heating and Cooling</h1>
          <span>Certified HVAC Professional</span>
        </div>
      </div>
    </div>
  );
};
