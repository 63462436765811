import React, { FunctionComponent } from 'react';
import { Card, Row, Image } from 'antd';

interface ServiceCardProps {
  title: string;
  image: string;
}

export const ServiceCard: FunctionComponent<ServiceCardProps> = (props: ServiceCardProps): JSX.Element => {
  const { title, image } = props;

  const getCoverImage = (): JSX.Element => {
    // return <img alt={title} src={image} />;
    return <Image src={image} />;
  };

  return (
    <Card className={'service-card'} cover={getCoverImage()} style={{ marginBottom: 75 }}>
      <Row justify={'center'}>
        <h2>{title}</h2>
      </Row>
    </Card>
  );
};
