import React, { FunctionComponent } from 'react';
import { Carousel, Row } from 'antd';
import { ToolFilled } from '@ant-design/icons';
import {
  COOLING_MAINTENANCE_URL,
  COOLING_REPAIR_URL,
  HEATING_MAINTENANCE_URL,
  HEATING_REPAIR_URL,
  THERMOSTAT_INSTALLATION_URL,
} from '../../constants';
import { ServiceCard } from '../ServiceCard';

const services = [
  { title: 'Heating Maintenance', image: HEATING_MAINTENANCE_URL },
  { title: 'Cooling Maintenance', image: COOLING_MAINTENANCE_URL },
  { title: 'Heating Repair', image: HEATING_REPAIR_URL },
  { title: 'Cooling Repair', image: COOLING_REPAIR_URL },
  { title: 'Thermostat Installation', image: THERMOSTAT_INSTALLATION_URL },
];

const getServices = () => {
  return services.map((service, index) => {
    return <ServiceCard key={index} image={service.image} title={service.title} />;
  });
};

export const ServicesSection: FunctionComponent = (): JSX.Element => {
  return (
    <div className={'section'}>
      <h2>
        <ToolFilled /> Services
      </h2>
      <p>Now Servicing Lancaster Pennsylvania Area.</p>
      <p>$75 Service Calls</p>
      <Row justify={'center'}>
        <h3>Provided HVAC Services</h3>
      </Row>
      <Carousel autoplay={true}>{getServices()}</Carousel>
    </div>
  );
};
