import React, { FunctionComponent } from 'react';
import { Home } from './pages/Home';
import { RouteMap } from './models/enums/RouteMap';
import { Route, Routes } from 'react-router-dom';

export const AppRoutes: FunctionComponent = (): JSX.Element => {
  return (
    <Routes>
      <Route element={<Home />} path={RouteMap.HOME} />
    </Routes>
  );
};
