import React, { FunctionComponent } from 'react';
import { Layout, Row } from 'antd';

export const Footer: FunctionComponent = (): JSX.Element => {
  return (
    <Layout.Footer className={'footer'}>
      <Row justify={'end'}>
        <span>Quality HVAC Services</span>
      </Row>
    </Layout.Footer>
  );
};
