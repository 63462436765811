import React, { FunctionComponent } from 'react';
import { EMAIL_ADDRESS, PHONE_NUMBER } from '../../constants';
import { ScheduleFilled } from '@ant-design/icons';

export const ContactSection: FunctionComponent = (): JSX.Element => {
  return (
    <div className={'section'}>
      <h2>
        <ScheduleFilled /> Contact Me
      </h2>
      <p>
        Call or text me anytime:{' '}
        <b>
          <a href={`tel:${PHONE_NUMBER}`}>{PHONE_NUMBER}</a>
        </b>
      </p>
      <p>
        I can also be reached via email:{' '}
        <b>
          <a href={`mailto:${EMAIL_ADDRESS}`}>{EMAIL_ADDRESS}</a>
        </b>
      </p>
    </div>
  );
};
