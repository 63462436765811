import React, { FunctionComponent } from 'react';
import { Layout } from 'antd';
import { BannerSection } from '../components/sections/BannerSection';
import { ServicesSection } from '../components/sections/ServicesSection';
import { ContactSection } from '../components/sections/ContactSection';
import { AboutSection } from '../components/sections/AboutSection';

const { Content } = Layout;

export const Home: FunctionComponent = (): JSX.Element => {
  return (
    <Content>
      <BannerSection />
      <ServicesSection />
      <ContactSection />
      <AboutSection />
    </Content>
  );
};
