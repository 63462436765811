export const BANNER_IMAGE_URL = 'https://long-hvac-assets.s3.amazonaws.com/background.jpg';
export const BANNER_VIDEO_URL = '';

export const COOLING_REPAIR_URL = 'https://long-hvac-assets.s3.amazonaws.com/services/cooling-repair.jpeg';
export const COOLING_MAINTENANCE_URL = 'https://long-hvac-assets.s3.amazonaws.com/services/cooling-maintenance.jpeg';
export const HEATING_REPAIR_URL = 'https://long-hvac-assets.s3.amazonaws.com/services/heating-repair.jpeg';
export const HEATING_MAINTENANCE_URL = 'https://long-hvac-assets.s3.amazonaws.com/services/heating-maintenance.jpeg';
export const THERMOSTAT_INSTALLATION_URL =
  'https://long-hvac-assets.s3.amazonaws.com/services/thermostat-installation.jpeg';

export const PROFILE_URL = 'https://long-hvac-assets.s3.amazonaws.com/profile.jpeg';

export const PHONE_NUMBER = '717-823-0605';
export const EMAIL_ADDRESS = 'longdhvac@gmail.com';
